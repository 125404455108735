<template>
  <div>
    <div class="m-1">
      <div class="matterCard">
        <div
          class="matterTitle"
          :style="{
            'background-color': courseWrapperFormationLine,
          }"
          :title="CourseWrapper.name"
        >
          <div
            class="matterSCT"
            v-b-popover.hover="
              $getVisibleNames('manual.credits', true, 'Créditos SCT')
            "
          >
            {{ CourseWrapper.credits }}
          </div>
          <div class="matterLink word-break">
            <span>{{ CourseWrapper.name.toUpperCase() }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "CourseCard",
  components: {},
  props: {
    CourseWrapper: {
      type: Object,
      required: true,
    },
    EgressProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { default_bg_color: "#CBDDE1" };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      school: "getInstitution",
      formation_lines: names.FORMATION_LINES,
      careers: names.CAREERS,
    }),
    career() {
      if (!this.EgressProfile) return null;
      return this.careers.find((x) => x.id == this.EgressProfile.career);
    },
    courseWrapperFormationLine() {
      if (!this.CourseWrapper || !this.career || !this.career.faculty)
        return this.default_bg_color;

      const formation_line = this.formation_lines.find(
        (x) =>
          x.id == this.CourseWrapper.formation_line &&
          x.faculties.includes(this.career.faculty)
      );
      if (formation_line) return formation_line.color;
      else return this.default_bg_color;
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.matterCard {
  min-width: 160px;
  min-height: 88px;
  box-sizing: border-box;
  display: flex;
  font-size: var(--thirth-font-size);
  color: #2e2e2e;
}
.matterTitle {
  position: relative;
  padding: 7px 9px;
  width: 100%;
  font-size: 7.5pt;
  background-color: #f4f4f4;
  border: 1px solid #7c7c7c;
  margin-bottom: 0;
}
.matterSCT {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #17b1cc;
  color: #fff;
  padding: 1px 5px;
  border-radius: 0px 0px 10px 10px;
  font-weight: bold;
  z-index: 2;
}
.matterSCT {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.matterTitle .matterLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  padding: 0.25rem;
  border-radius: 12px;
  line-height: 1.2;
  min-height: 72px;
}
.matterLink {
  min-width: 6rem;
}
.matterLink > a {
  color: #2e2e2e !important;
}
.matterLink > a:hover {
  color: #007bff !important;
}
/* Fuerza a romper las palabras largas en la siguiente línea */
/* .word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
} */
@media print {
  .matterTitle .matterLink a {
    text-decoration: none !important;
    line-height: 1.5;
  }
}
</style>